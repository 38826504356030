import React, { Component } from 'react';
import { Row, Col, InputGroup, FormControl, Button, Form } from 'react-bootstrap';
import cogoToast from 'cogo-toast';
import Loading from './Loading';



class Search extends Component {
    constructor(props) {
        super(props);
        this.state = {
            input: '',
            loading: false

        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();

        if (!this.state.input) {

            return

        }

        this.setState({
            loading: true
        });


        const url = `https://api-hadb.integrativebioinformatics.me/search/${this.state.input}`

        fetch(url)
            .then(response => response.json())
            .then(res => {
                this.setState({
                    loading: false
                });

                if (res.length > 0) {

                    this.props.history.push({
                        pathname: '/results',
                        state: {
                            res,
                        }
                    })
                } else {
                    cogoToast.warn("Sorry! No results found", {
                        position: 'top-right',
                        heading: 'Search results'
                    });

                }

            })
            .catch(err => {
                this.setState({
                    loading: false
                });


                console.log("error:", err);
            });

    }

    handleChange(e) {
        this.setState({
            input: e.target.value
        })

    }

    render() {
        const {loading} = this.state;
        return (
            <div className="bg">
                <Row className="bigInput">
                    {loading ? (<Loading/>) : null}
                    
                    <Col>
                        <Form onSubmit={this.handleSubmit}>

                            <InputGroup size="lg" onChange={this.handleChange}>
                                <FormControl
                                    placeholder="UNIPROT ID, HABDB ID, Sequences, Annotations "
                                    aria-label="Proteins names"
                                    aria-describedby="basic-addon2"
                                />
                                <InputGroup.Append>
                                    <Button id="basic-addon2" type="submit" ><i>search</i></Button>
                                </InputGroup.Append>
                            </InputGroup>
                        </Form>


                    </Col>
                </Row>
            </div>
            // <div className="bg">


            // </div>

        );
    }
}

export default Search;
