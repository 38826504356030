import React, { Component } from 'react';
import { Table } from 'react-bootstrap';

class TableDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        
        return (
            <div className="tableDetail">
                <Table striped bordered responsive>
                    <tbody>
                        {this.props.data.map((trans, i) =>
                            <tr key={i}>
                                <td className="title">{trans.title}</td>
                                <td>{trans.detail}</td>

                            </tr>)}
                    </tbody>
                </Table>
            </div>

        );
    }
}

export default TableDetails;