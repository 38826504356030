import React, { Component } from 'react';
import { Spinner } from 'react-bootstrap';

class Loading extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <div className="loading">
                <Spinner animation="border" variant="primary" />
            </div>

        );
    }
}

export default Loading;