import React, { Component } from 'react';
import { Container, Button, Col } from 'react-bootstrap';
// import ReactTable from "react-table";
import Loading from './Loading';
import TableDetails from './TableDetails';




class ResultsDetails extends Component {
    constructor(props) {
        super(props);
        const { match: { params } } = this.props;
        const { id } = params;
        const { idUNIPROT } = params;
        this.state = {

            id,
            idUNIPROT,
            loading: true,
            loadingUni: true,
            loadingPro: true,
            data: []
        };
        this.handleClick = this.handleClick.bind(this);
    }



    componentDidMount() {
        this.getTranscripts(this);
    }

    getTranscripts(el) {
        let uniCode = el.state.idUNIPROT.split('_');

        const urlTranscrip = `https://api-hadb.integrativebioinformatics.me/transcripts/${el.state.id}`;
        const urlUnicode = `https://api-hadb.integrativebioinformatics.me/uniprot/${uniCode[1]}`;
        const proannot = `https://api-hadb.integrativebioinformatics.me/proannot/${uniCode[1]}`;


        fetch(urlTranscrip).then(response => response.json())
            .then(res => {
                this.state.data.push({
                    title: 'Fasta',
                    detail: 'Button'
                })

                if (res.length > 0) {
                    const obj = res[0];
                    let transcrip = [];
                    for (const key in obj) {
                        if (obj.hasOwnProperty(key)) {
                            const element = obj[key];
                            if (key !== "sequence") {
                                transcrip.push({
                                    title: key,
                                    detail: element
                                })
                            } else {
                                this.setState({
                                    sequence: [{
                                        title: key,
                                        detail: element

                                    }]
                                })
                            }
                        }
                    }

                    this.setState({
                        loading: false,
                        transcrip,
                        data: res[0]
                    })
                }
            })
            .catch(err => {
                this.setState({
                    loading: false
                })
                console.log("error:", err);
            });


        fetch(urlUnicode).then(response => response.json())
            .then(res => {

                const obj = res
                let uniprot = [];

                for (const key in obj) {
                    let title = key.split('_');

                    if (obj.hasOwnProperty(key)) {

                        const element = obj[key];
                        uniprot.push({
                            title: title[1],
                            detail: element
                        })
                    }
                }

                this.setState({
                    loadingUni: false,
                    uniprot
                })

            })
            .catch(err => {
                this.setState({
                    loadingUni: false,
                })
                console.log("error:", err);
            });

        fetch(proannot).then(response => response.json())
            .then(res => {

                const obj = res
                let proannotation = [];

                for (const key in obj) {
                    if (obj.hasOwnProperty(key)) {
                        const element = obj[key];
                        if (key !== "lineage") {
                            proannotation.push({
                                title: key,
                                detail: element
                            })
                        } else {
                            let element = obj[key].toString();
                            element = element.replace(/,/g, ' > ');
                            proannotation.push({
                                title: key,
                                detail: element
                            })
                        }


                    }
                }

                this.setState({
                    loadingPro: false,
                    proannotation

                })

            })
            .catch(err => {
                this.setState({
                    loadingPro: false,
                })
                console.log("error:", err);
            });

    }

    handleClick() {

        const name = this.state.transcrip.filter(obj => obj.title === 'idUNIPROT');

        const element = document.createElement("a");
        const file = new Blob([this.state.sequence.detail], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = `${name[0].detail}.fasta`;
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();


    }



    render() {
        const { loading, loadingPro, loadingUni } = this.state;
        // const loading =  true
        console.log(this.state);
        
        return (
            <Container className="searchCards details">
                {loadingUni || loading || loadingPro ? (
                    <Loading />
                    ) : (
                        <Col md={10}>
                        <h2>{this.state.data.id}  –  {this.state.data.annotation}</h2>

                            <div className="sequence">
                                <Button id="basic-addon2" type="submit"
                                    onClick={this.handleClick}><i>Sequence Download</i></Button>
                            </div>

                            <div>
                                <h5>Transcript Information</h5>
                                <TableDetails data={this.state.transcrip} />
                            </div>
                            <div>
                                <h5>Annotations Cross-reference</h5>

                                <TableDetails data={this.state.proannotation} />
                            </div>
                            <div>
                                <h5>Database CrossReference</h5>

                                <TableDetails data={this.state.uniprot} />
                            </div>
                            <div>
                                <h5>Sequence</h5>

                                <TableDetails data={this.state.sequence} />
                            </div>
                        </Col>

                    )}
            </Container>
        );
    }
}

export default ResultsDetails;
