import React, { Component } from 'react';
import { Container } from 'react-bootstrap';

class AboutUs extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <Container className="sect">
                <h2>About us</h2>

                <div>
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Obcaecati rem, numquam iusto totam dolore ipsum autem quibusdam veniam animi dolorem illum. Eaque numquam quidem voluptatum aut a laudantium quas repellat!
                </div>
            </Container>

        );
    }
}

export default AboutUs;